var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"hb-primary hb-design-system-border-radius px-4 white--text mb-6"},[_c('hb-header',{attrs:{"divider":false}},[_c('hb-page-header',{attrs:{"title":"Color System"},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('span',{staticClass:"font-weight-medium pr-1"},[_vm._v("IMPORTANT:")]),_vm._v("You must add required global CSS classes/names and rules for these colors as outlined here: "),_c('hb-link',{staticClass:"ml-1",attrs:{"to":'/design-system/global-css'}},[_vm._v("Go to Global CSS Page")])]},proxy:true}])})],1)],1),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Primary Brand Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers,"items":[
          {
            name: 'hb-primary-lighter',
            color: 'Lighter',
            css: 'background-color: #E0F5F5;',
            description: 'Selection Shading'
          },
          {
            name: 'hb-primary-light',
            color: 'Light',
            css: 'background-color: #8DE3E3;',
            description: ''
          },
          {
            name: 'hb-primary',
            color: 'Primary',
            css: 'background-color: #47C0BF;',
            description: 'Primary Button Gradient'
          },
          {
            name: 'hb-primary-dark',
            color: 'Dark',
            white: true,
            css: 'background-color: #00848E;',
            description: 'CTA Color'
          },
          {
            name: 'hb-primary-darkest',
            color: 'Darkest',
            white: true,
            css: 'background-color: #003135;',
            description: 'Header Color'
          }
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('div',{staticClass:"hb-flex-all rectangle",class:item.name},[_c('span',{staticClass:"rectangle-center font-weight-medium",class:{ 'white--text': item.white }},[_vm._v(_vm._s(item.color))])])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"my-6 pb-1",attrs:{"title":"Cloud Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers,"items":[
          {
            name: 'hb-cloud-lighter',
            color: 'Lighter',
            css: 'background-color: #F9FAFB;',
            description: 'Background'
          },
          {
            name: 'hb-cloud-light',
            color: 'Light',
            css: 'background-color: #F4F6F8;',
            description: 'Text Form Label Block'
          },
          {
            name: 'hb-cloud',
            color: 'Cloud',
            css: 'background-color: #DFE3E8;',
            description: 'Cards, Tables, Dividers'
          },
          {
            name: 'hb-cloud-dark',
            color: 'Dark',
            css: 'background-color: #C4CDD5;',
            description: 'Text Form Bounding Box'
          }
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('div',{staticClass:"hb-flex-all rectangle",class:item.name},[_c('span',{staticClass:"rectangle-center font-weight-medium",class:{ 'white--text': item.white }},[_vm._v(_vm._s(item.color))])])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Text Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers2,"items":[
          {
            name: 'hb-text-light',
            color: 'Light',
            css: 'color: #637381;',
            description: 'Disabled/Empty'
          },
          {
            name: 'hb-text-night',
            color: 'Night',
            css: 'color: #101318;',
            description: 'Default Text Color'
          },
          {
            name: 'hb-text-error',
            color: 'Error',
            css: 'color: #FB4C4C;',
            description: 'Form Validation Error'
          }
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('span',{class:item.name},[_vm._v(_vm._s(item.color))])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Link Colors (alternative usage vs. <hb-link> component, please use <hb-link> component if possible)"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers2,"items":[
          {
            name: 'hb-link',
            color: 'Action',
            css: 'color: #00848E;',
            description: ''
          },
          {
            name: 'hb-link-secondary',
            color: 'email@email.com',
            css: 'color: #306FB6;',
            description: ''
          }
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('span',{class:item.name},[_vm._v(_vm._s(item.color))])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Status Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers,"items":[
          {
            name: 'hb-error',
            color: 'Error',
            css: 'background-color: #FB4C4C;',
            description: ''
          },
          {
            name: 'hb-caution',
            color: 'Caution',
            css: 'background-color: #FFD600;',
            description: ''
          },
          {
            name: 'hb-success',
            color: 'Success',
            white: true,
            css: 'background-color: #02AD0F;',
            description: ''
          },
          {
            name: 'hb-guidance',
            color: 'Guidance',
            css: 'background-color: #3D8FFF;',
            description: ''
          },
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('div',{staticClass:"hb-flex-all rectangle",class:item.name},[_c('span',{staticClass:"rectangle-center font-weight-medium",class:{ 'white--text': item.white }},[_vm._v(_vm._s(item.color))])])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Status Shade Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers,"items":[
          {
            name: 'hb-error-shade',
            color: 'Error Shade',
            css: 'background-color: #F8E5E1;',
            description: ''
          },
          {
            name: 'hb-caution-shade',
            color: 'Caution Shade',
            css: 'background-color: #FFF9DA;',
            description: ''
          },
          {
            name: 'hb-success-shade',
            color: 'Success Shade',
            css: 'background-color: #E1FAE3;',
            description: ''
          },
          {
            name: 'hb-guidance-shade',
            color: 'Guidance Shade',
            css: 'background-color: #E7F1FF;',
            description: ''
          }
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('div',{staticClass:"hb-flex-all rectangle",class:item.name},[_c('span',{staticClass:"rectangle-center font-weight-medium",class:{ 'white--text': item.white }},[_vm._v(_vm._s(item.color))])])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Close/Menu Icon Button Hover Over Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers,"items":[
          {
            name: 'n/a',
            color: 'Error Hover',
            css: 'background-color: #EFCFCC;',
            description: ''
          },
          {
            name: 'n/a',
            color: 'Caution Hover',
            css: 'background-color: #F5EBB7;',
            description: ''
          },
          {
            name: 'n/a',
            color: 'Success Hover',
            css: 'background-color: #C5EDC9;',
            description: ''
          },
          {
            name: 'n/a',
            color: 'Guidance Hover',
            css: 'background-color: #BAD7FF;',
            description: 'Exact color is not finalized / not approved'
          },
          {
            name: 'n/a',
            color: 'Quick Actions Hover',
            css: 'background-color: #C6E5E2;',
            description: 'Quick Actions Notification Close Button Hover Color'
          },
          {
            name: 'n/a',
            color: 'Primary Hover',
            css: 'background-color: #CAEAEA;',
            description: 'Default Modal Close Button Hover Over Color'
          },
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('div',{staticClass:"hb-flex-all rectangle",style:(item.css)},[_c('span',{staticClass:"rectangle-center font-weight-medium",class:{ 'white--text': item.white }},[_vm._v(_vm._s(item.color))])])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)]),_c('hb-card',{staticClass:"mt-4 mb-6",attrs:{"title":"Chart Colors"}},[_c('div',{staticClass:"pa-6"},[_c('hb-data-table',{attrs:{"headers":_vm.headers,"items":[
          {
            name: 'hb-chart-pink',
            color: 'Pink',
            css: 'background-color: #CE6EE2;',
            description: ''
          },
          {
            name: 'hb-chart-green',
            color: 'Green',
            css: 'background-color: #4AC3AB;',
            description: ''
          },
          {
            name: 'hb-chart-orange',
            color: 'Orange',
            css: 'background-color: #FAA838;',
            description: ''
          },
          {
            name: 'hb-chart-royal-blue',
            color: 'Royal Blue',
            css: 'background-color: #4B5FC7;',
            description: ''
          },
          {
            name: 'hb-chart-cyan',
            color: 'Cyan',
            css: 'background-color: #67CFF5;',
            description: ''
          },
          {
            name: 'hb-chart-gray',
            color: 'Gray',
            css: 'background-color: #C6CDD4;',
            description: ''
          },
          {
            name: 'hb-chart-pumpkin',
            color: 'Pumpkin',
            css: 'background-color: #F17A37;',
            description: ''
          },
          {
            name: 'hb-chart-red',
            color: 'Red',
            css: 'background-color: #E24F60;',
            description: ''
          },
          {
            name: 'hb-chart-teal',
            color: 'Teal',
            css: 'background-color: #38AFC6;',
            description: ''
          },
          {
            name: 'hb-chart-yellow',
            color: 'Yellow',
            css: 'background-color: #E4DE3E;',
            description: ''
          }
        ]},scopedSlots:_vm._u([{key:"item.name",fn:function({ item }){return [_vm._v(" ."+_vm._s(item.name)+" ")]}},{key:"item.color",fn:function({ item }){return [_c('div',{staticClass:"hb-flex-all rectangle",class:item.name},[_c('span',{staticClass:"rectangle-center font-weight-medium",class:{ 'white--text': item.white }},[_vm._v(_vm._s(item.color))])])]}},{key:"item.description",fn:function({ item }){return [_vm._v(" "+_vm._s(item.description)+" ")]}}])})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }