<template>
  <div>
    <div
      class="hb-primary hb-design-system-border-radius px-4 white--text mb-6"
    >
      <hb-header :divider="false">
        <hb-page-header title="Color System">
          <template v-slot:description>
            <span class="font-weight-medium pr-1">IMPORTANT:</span>You must add required global CSS classes/names and rules for these colors as outlined here:
            <hb-link class="ml-1" :to="'/design-system/global-css'"
              >Go to Global CSS Page</hb-link
            >
          </template>
        </hb-page-header>
      </hb-header>
    </div>

    <hb-card title="Primary Brand Colors" class="mt-4 mb-6">
      <div class="pa-6">
        <hb-data-table
          :headers="headers"
          :items="[
            {
              name: 'hb-primary-lighter',
              color: 'Lighter',
              css: 'background-color: #E0F5F5;',
              description: 'Selection Shading'
            },
            {
              name: 'hb-primary-light',
              color: 'Light',
              css: 'background-color: #8DE3E3;',
              description: ''
            },
            {
              name: 'hb-primary',
              color: 'Primary',
              css: 'background-color: #47C0BF;',
              description: 'Primary Button Gradient'
            },
            {
              name: 'hb-primary-dark',
              color: 'Dark',
              white: true,
              css: 'background-color: #00848E;',
              description: 'CTA Color'
            },
            {
              name: 'hb-primary-darkest',
              color: 'Darkest',
              white: true,
              css: 'background-color: #003135;',
              description: 'Header Color'
            }
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <div class="hb-flex-all rectangle" :class="item.name">
              <span
                class="rectangle-center font-weight-medium"
                :class="{ 'white--text': item.white }"
                >{{ item.color }}</span
              >
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card title="Cloud Colors" class="my-6 pb-1">
      <div class="pa-6">
        <hb-data-table
          :headers="headers"
          :items="[
            {
              name: 'hb-cloud-lighter',
              color: 'Lighter',
              css: 'background-color: #F9FAFB;',
              description: 'Background'
            },
            {
              name: 'hb-cloud-light',
              color: 'Light',
              css: 'background-color: #F4F6F8;',
              description: 'Text Form Label Block'
            },
            {
              name: 'hb-cloud',
              color: 'Cloud',
              css: 'background-color: #DFE3E8;',
              description: 'Cards, Tables, Dividers'
            },
            {
              name: 'hb-cloud-dark',
              color: 'Dark',
              css: 'background-color: #C4CDD5;',
              description: 'Text Form Bounding Box'
            }
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <div class="hb-flex-all rectangle" :class="item.name">
              <span
                class="rectangle-center font-weight-medium"
                :class="{ 'white--text': item.white }"
                >{{ item.color }}</span
              >
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card title="Text Colors" class="mt-4 mb-6">
      <div class="pa-6">
        <hb-data-table
          :headers="headers2"
          :items="[
            {
              name: 'hb-text-light',
              color: 'Light',
              css: 'color: #637381;',
              description: 'Disabled/Empty'
            },
            {
              name: 'hb-text-night',
              color: 'Night',
              css: 'color: #101318;',
              description: 'Default Text Color'
            },
            {
              name: 'hb-text-error',
              color: 'Error',
              css: 'color: #FB4C4C;',
              description: 'Form Validation Error'
            }
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <span :class="item.name">{{ item.color }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card
      title="Link Colors (alternative usage vs. &lt;hb-link> component, please use &lt;hb-link> component if possible)"
      class="mt-4 mb-6"
    >
      <div class="pa-6">
        <hb-data-table
          :headers="headers2"
          :items="[
            {
              name: 'hb-link',
              color: 'Action',
              css: 'color: #00848E;',
              description: ''
            },
            {
              name: 'hb-link-secondary',
              color: 'email@email.com',
              css: 'color: #306FB6;',
              description: ''
            }
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <span :class="item.name">{{ item.color }}</span>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card title="Status Colors" class="mt-4 mb-6">
      <div class="pa-6">
        <hb-data-table
          :headers="headers"
          :items="[
            {
              name: 'hb-error',
              color: 'Error',
              css: 'background-color: #FB4C4C;',
              description: ''
            },
            {
              name: 'hb-caution',
              color: 'Caution',
              css: 'background-color: #FFD600;',
              description: ''
            },
            {
              name: 'hb-success',
              color: 'Success',
              white: true,
              css: 'background-color: #02AD0F;',
              description: ''
            },
            {
              name: 'hb-guidance',
              color: 'Guidance',
              css: 'background-color: #3D8FFF;',
              description: ''
            },
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <div class="hb-flex-all rectangle" :class="item.name">
              <span
                class="rectangle-center font-weight-medium"
                :class="{ 'white--text': item.white }"
                >{{ item.color }}</span
              >
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card title="Status Shade Colors" class="mt-4 mb-6">
      <div class="pa-6">
        <hb-data-table
          :headers="headers"
          :items="[
            {
              name: 'hb-error-shade',
              color: 'Error Shade',
              css: 'background-color: #F8E5E1;',
              description: ''
            },
            {
              name: 'hb-caution-shade',
              color: 'Caution Shade',
              css: 'background-color: #FFF9DA;',
              description: ''
            },
            {
              name: 'hb-success-shade',
              color: 'Success Shade',
              css: 'background-color: #E1FAE3;',
              description: ''
            },
            {
              name: 'hb-guidance-shade',
              color: 'Guidance Shade',
              css: 'background-color: #E7F1FF;',
              description: ''
            }
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <div class="hb-flex-all rectangle" :class="item.name">
              <span
                class="rectangle-center font-weight-medium"
                :class="{ 'white--text': item.white }"
                >{{ item.color }}</span
              >
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card title="Close/Menu Icon Button Hover Over Colors" class="mt-4 mb-6">
      <div class="pa-6">
        <hb-data-table
          :headers="headers"
          :items="[
            {
              name: 'n/a',
              color: 'Error Hover',
              css: 'background-color: #EFCFCC;',
              description: ''
            },
            {
              name: 'n/a',
              color: 'Caution Hover',
              css: 'background-color: #F5EBB7;',
              description: ''
            },
            {
              name: 'n/a',
              color: 'Success Hover',
              css: 'background-color: #C5EDC9;',
              description: ''
            },
            {
              name: 'n/a',
              color: 'Guidance Hover',
              css: 'background-color: #BAD7FF;',
              description: 'Exact color is not finalized / not approved'
            },
            {
              name: 'n/a',
              color: 'Quick Actions Hover',
              css: 'background-color: #C6E5E2;',
              description: 'Quick Actions Notification Close Button Hover Color'
            },
            {
              name: 'n/a',
              color: 'Primary Hover',
              css: 'background-color: #CAEAEA;',
              description: 'Default Modal Close Button Hover Over Color'
            },
          ]"
        >
          <template v-slot:item.name="{ item }"> {{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <div class="hb-flex-all rectangle" :style="item.css">
              <span
                class="rectangle-center font-weight-medium"
                :class="{ 'white--text': item.white }"
                >{{ item.color }}</span
              >
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>

    <hb-card title="Chart Colors" class="mt-4 mb-6">
      <div class="pa-6">
        <hb-data-table
          :headers="headers"
          :items="[
            {
              name: 'hb-chart-pink',
              color: 'Pink',
              css: 'background-color: #CE6EE2;',
              description: ''
            },
            {
              name: 'hb-chart-green',
              color: 'Green',
              css: 'background-color: #4AC3AB;',
              description: ''
            },
            {
              name: 'hb-chart-orange',
              color: 'Orange',
              css: 'background-color: #FAA838;',
              description: ''
            },
            {
              name: 'hb-chart-royal-blue',
              color: 'Royal Blue',
              css: 'background-color: #4B5FC7;',
              description: ''
            },
            {
              name: 'hb-chart-cyan',
              color: 'Cyan',
              css: 'background-color: #67CFF5;',
              description: ''
            },
            {
              name: 'hb-chart-gray',
              color: 'Gray',
              css: 'background-color: #C6CDD4;',
              description: ''
            },
            {
              name: 'hb-chart-pumpkin',
              color: 'Pumpkin',
              css: 'background-color: #F17A37;',
              description: ''
            },
            {
              name: 'hb-chart-red',
              color: 'Red',
              css: 'background-color: #E24F60;',
              description: ''
            },
            {
              name: 'hb-chart-teal',
              color: 'Teal',
              css: 'background-color: #38AFC6;',
              description: ''
            },
            {
              name: 'hb-chart-yellow',
              color: 'Yellow',
              css: 'background-color: #E4DE3E;',
              description: ''
            }
          ]"
        >
          <template v-slot:item.name="{ item }"> .{{ item.name }} </template>
          <template v-slot:item.color="{ item }">
            <div class="hb-flex-all rectangle" :class="item.name">
              <span
                class="rectangle-center font-weight-medium"
                :class="{ 'white--text': item.white }"
                >{{ item.color }}</span
              >
            </div>
          </template>
          <template v-slot:item.description="{ item }">
            {{ item.description }}
          </template>
        </hb-data-table>
      </div>
    </hb-card>
  </div>
</template>
<script type="text/babel">
export default {
  name: "DesignSystemColorSystem",
  data: function() {
    return {
      headers: [
        { text: "CSS Class Name", value: "name" },
        { text: "Background Color", value: "color", width: 175 },
        { text: "CSS", value: "css" },
        { text: "Description", value: "description" }
      ],
      headers2: [
        { text: "CSS Class Name", value: "name" },
        { text: "Color", value: "color" },
        { text: "CSS", value: "css" },
        { text: "Description", value: "description" }
      ]
    };
  }
};
</script>

<style scoped>
.rectangle {
  min-width: 70px;
  height: 100px;
}
</style>
